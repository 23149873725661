<template>
  <base-layout :loading="loading">
    <template v-slot:title>
      <div class="text-lg">
        パスワードを再設定する
      </div>
    </template>
    <div
      class="flex items-center h-full justify-center"
    >
      <form
        @submit.prevent="onSubmit"
        class="px-10 pt-10"
      >
        <text-field
          type="text"
          label="ユーザID"
          class="mb-5"
          v-model="formData.email"
        ></text-field>
          <button
            type="submit"
            class="w-full success "
            :disabled="!formData.email"
          >
            送信
          </button>
      </form>
    </div>
  </base-layout>
</template>
<script >

import {defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/types/Auth';
import useFlashMessage from '@/types/FlashMessage';

export default defineComponent({
  components: {
    // GSignInButton
  },
  setup() {
    const router = useRouter();

    const {
      setMessage  
    } = useFlashMessage();

    const {
      resetPassword
    } = useAuth();

    
    const formData = ref({
      email: ''
    });

    const loading = ref(false);

    const onSubmit = async () => {
      // validate
      try {
        loading.value = true;
         console.log('loading', loading.value)
        await resetPassword(formData.value);
        router.push({ name: 'RequestResetPasswordDone' }) ;
      } catch (err) {
        console.log(err)
        if (err.response) {
          const { data } = err.response;
          if (data && data.error) {
            setMessage({ type: 'error', message: data.error })
          } else if (data) {
            const errors = Object.values(data);
            if (errors.length && errors[0].length) {
              setMessage({ type: 'error', message: errors[0][0] })
            }
          }
        } else {
          console.error('network error')
        }
      }
      loading.value = false;
      
    };
    return {
      loading,
      formData,
      onSubmit,
    };
  }
})
</script>


